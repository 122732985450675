<template>
  <div class="offers">
    <v-data-table
      dense
      :headers="headers"
      :items="list"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Офферы</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            :to="{ name: 'offer-create' }"
          >
            Добавить
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Вы хотите удалить оффер?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Нет
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  Да
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.logo="{ item }">
        <img class="offers__logo" :src="item.logo" alt="" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<style>
.offers__logo {
  max-width: 200px;
  width: 100%;
  height: 65px;
  object-fit: contain;
}
</style>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapGetters } = createNamespacedHelpers("offers");

export default {
  name: "Offers",
  data: () => ({
    dialogDelete: false,
    curretnId: null,
    headers: [
      { text: "Название", value: "title" },
      { text: "Лого", value: "logo" },
      { text: "действие", value: "actions", sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(["list"]),
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions(["getList", "delete"]),
    editItem(item) {
      this.$router.push({ name: "offer-edit", params: { id: item.id } });
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.curretnId = item.id;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.curretnId = null;
    },
    deleteItemConfirm() {
      this.delete(this.curretnId)
        .then(() => {
          return this.getList();
        })
        .then(() => {
          this.closeDelete();
        });
    },
  },
};
</script>
